import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResultData, ResultText } from '../assets/data/resultdata';
import KakaoShareBtn from '../component/KakaoShareBtn';
import Footer from './footer';
import axios from 'axios';
import Adfit from "./Adfit";
import { UrlAPI } from './parameter';
import "../assets/fonts/font.css"
import TestRRSimg from '../assets/rrs-title2.png';
import TestKTAimg from '../assets/kta-title.png';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import Swal from "sweetalert2";

import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

let dateString;

// 결과마다 다른 배경색 부여용 변수
let backgroundColor = '#efefcf';

const Result = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const URLsplitted = window.location.href.split('result/');
  const URLparams = URLsplitted[1];
  const [resultData, setResultData] = React.useState({});
  const [userID, setUserID] = useState(0);

  //DB 데이터 조회/삽입 중지
  // const SetDate = () => {
  //   let today = new Date();
  //   let year = today.getFullYear();
  //   let month = ('0' + (today.getMonth() +1)).slice(-2);
  //   let day = ('0' + today.getDate()).slice(-2);
  //   let hours   = ('0' + today.getHours()).slice(-2);
  //   let minutes = ('0' + today.getMinutes()).slice(-2);
  //   let seconds = ('0' + today.getSeconds()).slice(-2);
  //   dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  // };

  // SetDate(); 

  useEffect(() => {
    let result = ResultData.find((s) => s.token === URLparams);
    setResultData(result)
    // try{
    //   PutResultData();
    // } catch (err) {
    //   this.errorCatch(err);
    // };
  }, [URLparams]);
  

  // DB 데이터 조회/삽입 중지 
  // const getUserID = async() => {
  //   axios
  //   .get(UrlAPI)
  //   .then(response => {
  //       setUserID(response.data);
  //       PutResultData();
  //   });
  // }

  // const PutResultData = async() => {
  //   let params = {
  //     'userID' : (Number(userID) + 1).toString(),
  //     'date'   : dateString,
  //   };
  //   axios.post(UrlAPI, params);
  // };

  const luckyScore = (scores) => {
    try {
      let scoreIcon = [];
      for (let i = 0; i < scores.length; i++) {
        switch (Number(scores[i])) {
          case 0:
            scoreIcon.push('❤️🤍🤍');
            break;
          case 1:
            scoreIcon.push('❤️❤️🤍');
            break;
          case 2:
            scoreIcon.push('❤️❤️❤️');
            break;
        }
      };

      return (
        <div>
          <br />
          <DescName2>   - 운세 지수 -</DescName2>
          <DescName2>사랑운 : {scoreIcon[0]}</DescName2>
          <DescName2>성공운 : {scoreIcon[1]}</DescName2>
          <DescName2>재물운 : {scoreIcon[2]}</DescName2>
        </div>
      )
    } catch (error) {
      console.log(`운 계산 중 Error 발생: ${error}`);
    }
  };

  var options = {
    quality: 0.5
  };
  const onDownloadBtn = () => {
    console.log("savesavesavesaveasve");
    domtoimage
    .toBlob(document.getElementById('.capture'), { bgcolor: resultData.backgroundColor }, options)
    .then(function(blob){
      window.saveAs(blob,'newyearrabbit.png')
    })
  };
  //   domtoimage
  //     .toBlob(document.querySelector('.capture'), { bgcolor: resultData.backgroundColor }, options)
  //     .then((blob) => {
  //       saveAs(blob, 'newyearrabbit.png');
  //     })
  // };

  const currentUrl = window.location.href;
  return (
    <Wrapper style={{ backgroundColor: resultData.backgroundColor }}>
      {/* {changeBackground(resultData.best)} */}
      <div className="capture" >
        <Header> 럭키토끼 테스트</Header>
        <Contents1>
          <Title>운세 결과는?</Title>
          <LogoImage>
            <img alt='img_result'
              src={resultData.image} width={300} height={300}
              className="rounded-circle"
              style={{ borderRadius: '50%', border: '2px solid ' + resultData.borderColor }}
            />
          </LogoImage>
          <Desc>당신은</Desc>
          <DescName>'{resultData.name}' 입니다!</DescName>
          <DescName2>'{resultData.comment}'</DescName2>
          {luckyScore(resultData.best)}
          <ResImage>
            <img alt='img_desc' src={resultData.r_image} width={360} height='auto' />
            <br />
            <img alt='img_desc2' src={resultData.r_image2} width={360} height='auto' />
          </ResImage>
          {/* {ResultText(resultData.id)} */}
          <br />
        </Contents1>
      </div>
      <Contents2>
      <Button style={{ marginRight: '10px' }} class="btn btn-primary btn-lg"  onClick={() => { navigate('/'); }}>
            REPLAY
      </Button>

        {/* <ButtonGroup>
          <Button style={{ marginRight: '10px' }} onClick={() => { navigate('/'); }}>
            REPLAY
          </Button>
          <Button style={{ backgroundColor : 'black', border: '0px ' }}
            alt="이미지저장"
            onClick={() => {
              onDownloadBtn()
              Swal.fire({
                icon: "success",
                title: '저장을 시작합니다',
                text: '다운로드 폴더에 저장됩니다iOS에서는 동작하지 않아요',
                type: "success",
                confirmButtonText: '확인',
                confirmButtonColor: '#3085d6',
                className: 'swal-wide',
                width: '20em',
                position: 'center',
      
              })
            }}>
            이미지저장
          </Button >
        </ButtonGroup> */}

        <FlexContainer>
          <h5>공유하기</h5>
          <GridContainer>
            <FacebookShareButton url={currentUrl}>
              <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={currentUrl}>
              <FacebookMessengerIcon size={48} round={true} borderRadius={24}></FacebookMessengerIcon>
            </FacebookMessengerShareButton>
            {/* <TwitterShareButton url={currentUrl}>
                  <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
                </TwitterShareButton> */}
            <CopyToClipboard text={currentUrl}>
              <URLShareButton>URL</URLShareButton>
            </CopyToClipboard>
            <KakaoShareBtn data={{ data: resultData, pageID: 'resultPage' }} />
          </GridContainer>
        </FlexContainer>
        <br/>
        {/* <Adfit height={50} width={320} unit={"8pXo8z34ROw1vPf6"} scrFlag={true} position={"middle"}></Adfit> */}
        
        <h4 />👇 다른 테스트하러 가기 👇<h4 /><br/>
        <img src={TestRRSimg} width={230} height={60} onClick={() => { window.open('https://advancer-rrs.click/'); }} /><br/>
        <img src={TestKTAimg} width={230} height={60} onClick={() => { window.open('https://www.advancer-katalk.click/'); }} />


        
      </Contents2>

      <Footer></Footer>
      {/* <FooterWrapper>
          이 포스팅은 쿠팡 파트너스 활동의 일환으로, <br/>이에 따른 일정액의 수수료를 제공받습니다.
        </FooterWrapper> */}
      <Adfit height={100} width={320} unit={"kFdWVxcz4wh0SxJY"} scrFlag={true} position={"buttom"}></Adfit>
    </Wrapper >
  );
}

export default Result;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: black;
  text-align: center
`

const Wrapper = styled.div`
  text-align: center
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  white-space: pre-line;

`
// linear-gradient(to bottom, #efefcf, #efffcf);
const Header = styled.div`
  padding-top: 2rem;
  font-size: 27pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "CookieRun_Regular";
  font-weight: 500;
  color: black;
  text-align: center
`
const Contents1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  text-align: center
  white-space: pre-line;
`
const Contents2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
`
const Title = styled.div`
  font-size: 25px;
  margin-top: 20px;
`

// 토기 그림 원형 테두리 만들기
const LogoImage = styled.div`
  margin-top: 30px;
`

const ResImage = styled.div`
  margin-top: 10px;
`
const Desc = styled.div`
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
  white-space: pre-line;
`
const DescName = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  white-space: pre-wrap;
`
const DescName2 = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
  white-space: pre-wrap;
`
const DescSub = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5px;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`
// 제목과 버튼을 감싸는 컨테이너
const FlexContainer = styled.div`
  margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
// 버튼을 배치시키는 컨테이너
const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;
const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;