import React from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { QuestionData } from '../assets/data/questiondata';
import Footer from './footer';
import Adfit from "./Adfit";
import "../assets/fonts/font.css"
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'

const Question = () => {
  const [rabbit, setRabbit] = React.useState();
  const [questionNo, setQuestionNo] = React.useState(0);
  const [totalScore, setTotalScore] = React.useState([
    { id : "LOVE", score: 0 },
    { id : "SUCCESS", score: 0 },
    { id : "MONEY", score: 0 },
  ]);

  let rabbitResult;
  const navigate = useNavigate();
  const questionNum = 6;
  
  const handleClickBtn = (id, data, type) => { //id - 1 2 3 4 5 6 //data - 1 0 //type - LOVE SUCCESS MONEY
      const newScore = totalScore.map((s) => 
        s.id === type ? { id:s.id, score:s.score+data } : s
      );
      console.log(`id: ${id}`);
      console.log(`data: ${data}`);
      console.log(`type: ${type}`);
      console.log(`newScore[0].score: ${newScore[0].score}`);
      console.log(`newScore[1].score: ${newScore[1].score}`);
      console.log(`newScore[2].score: ${newScore[2].score}`);
      console.log("");

      setTotalScore(newScore);
      if( id <= questionNum ) {
        setQuestionNo(questionNo+1);
        if (id == questionNum){ //마지막 문제면 이젠 rabbit을 도출해내야됨 
          rabbitResult = newScore.reduce( //reduce 함수로 하나의 결과값을 반환한다.
            (acc, current) => 
              String(acc) + (String(current.score)),
              //acc + (current.score >= 2 ? current.id.substring(0,1) : current.id.substring(1,2)),
              ""
          ); 
          setRabbit(rabbitResult);
          console.log('rabbit 계산: ', rabbit);
        }
      } 
  }
  
  const showQuestionNum = (id) => {
    if (id <= questionNum) return (<QuestionNo> 질문.{id} </QuestionNo> )
  };

  const showTitle = (id) => {
    if (id <= questionNum) {return (<Title>{QuestionData[questionNo].title}</Title>);}
    else {return (<TitleSub>{QuestionData[questionNo].title}</TitleSub>)} //쿠팡 
  }

  const ShowButton = (id) => {
    if (id <= questionNum){
    return (
      <ButtonGroup>
        <Button onClick={()=>handleClickBtn(id, 1, QuestionData[questionNo].type)} style={{width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerA}</Button>
        <Button onClick={()=>handleClickBtn(id, 0, QuestionData[questionNo].type)} style={{width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerB}</Button>
      </ButtonGroup>
    );
  } else { //쿠팡
    return (
      <div>
      <ButtonGroup>
        <Button onClick={()=>Notice(rabbit)} style={{"background-color": "green", "border": "none", width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerA}</Button>
      </ButtonGroup>
      <FooterWrapper>
      이 포스팅은 쿠팡 파트너스 활동의 일환으로, <br/>이에 따른 일정액의 수수료를 제공받습니다.
      <br/><br />
      <iframe src="https://ads-partners.coupang.com/widgets.html?id=712312&template=carousel&trackingCode=AF6214453&subId=&width=230&height=60&tsource=" width="230" height="60" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
    </FooterWrapper>
    </div>
    )
  }
  }

  const ShowAd1 = (id) => {
    if (id<=questionNum){
      return(
        <Adfit height={100} width={320} unit={"kFdWVxcz4wh0SxJY"} scrFlag={true} position={"middle"}></Adfit>
      );
    } 
  }
  
  const ShowAd2 = (id) => {
    if (id<=questionNum){
      return(
        <Adfit height={100} width={320} unit={"kFdWVxcz4wh0SxJY"} scrFlag={true} position={"buttom"}></Adfit>
        );
    } 
  }

  const Notice = (rabbit) => {
      console.log('rabbit: ', rabbit);
      window.open('https://link.coupang.com/a/8SQ9H','_blank');
      navigate(`/result/${setURLparams(rabbit)}`);
  }

  const setURLparams = (rabbit) => {
    switch (rabbit){
      case '201': case '210': case '211': // [사랑] 01. 돌진 토끼
        return 'cf4175fd7b'
      case '200': // [사랑] 02. 어쩌다 사랑 토끼
        return 'ad0bbf2599'
      case '110': // [사랑] 03. 인기쟁이 토끼
        return '40e482eaa0'
      case '100': // [사랑] 04. 큐피드 토끼
        return 'a42b7064df'
      case '122': case '212': case '221': case '222': // [애매] 05. 마성의 매력 토끼
        return '64334b1b4a'
      case '202': // [애매] 06. 두 마리 토끼 다 잡은 토끼
        return 'd2d63f789a'
      case '022': // [애매] 07. 두 마리 토끼 다 잡은 토끼
        return 'b3b1d185e4'
      case '220': // [애매] 08. 두 마리 토끼 다 잡은 토끼
        return '0838bab7a4'
      case '000': // [애매] 09. 나혼산 토끼
        return 'ad998fcad0'
      case '111': // [애매] 10. 뭘해도 중간은 가는 토끼
        return '15789b70e2'
      case '020': case '121': // [성공] 11. 대장토끼
        return 'bfaf148cd7'
      case '021': // [성공] 12. 벼락성공 토끼
        return '82791fa00c'
      case '010': case '120': // [성공] 13. 복주머니 토끼
        return '6ab5e4b717'
      case '011': case '001': case '101': // [재물] 14. 돈 많은 백수 토끼
        return '61f4343a13'
      case '012': case '102': // [재물] 15. 쇼미더머니 토끼
        return 'bdd7320e1e'
      case '112': case '002': // [재물] 16. 재벌집 막내 토끼
        return '0ac11dbcca' 
    }
      
  }
     return (
      <div style={{ }}>
      <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} />
        <Wrapper>
            <Bar>
              <ProgressBar striped now={(questionNo/QuestionData.length)*100}/>
            </Bar>
            {showQuestionNum(QuestionData[questionNo].id)}
            <br/>
            {/* <QImage>
              <img alt='question_img' src={QuestionData[questionNo].img}/>
            </QImage> */}

            {showTitle(QuestionData[questionNo].id)}
            
            {/* {ShowAd1(QuestionData[questionNo].id)} */}
            <br/><br/>
            {ShowButton(QuestionData[questionNo].id)}
            <Footer></Footer>
            {ShowAd2(QuestionData[questionNo].id)}
        </Wrapper>
        </div>
    );
}

export default Question;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: black;
  text-align: center
`

const QImage = styled.div `
  text-align: center;
`

const Wrapper = styled.div `
  height: 100vh;
  // height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, #d8cfff, #d8dfff);
  text-align: center
  white-space: pre-line;

  `
const Bar = styled.div `
  padding-top: 2rem;
`
const QuestionNo = styled.div `
  margin: 2rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  font-weight: 500;
  color: black;

`
const Title = styled.div `
  padding: 1rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  color: black;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`
const TitleSub = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 17px;
  text-align: center;
  color: black;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `

const ButtonGroup = styled.div `
	display: flex;
	flex-direction : column;
	align-items: center;
	justify-content: center;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";

`
