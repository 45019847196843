import rabbit1 from '../result_rabbit_img/rabbit1.png'; //돌진토끼 사랑성공재물210
import rabbit2 from '../result_rabbit_img/rabbit2.png'; //어쩌다 사랑 토끼 200
import rabbit3 from '../result_rabbit_img/rabbit3.png'; //인기쟁이 토끼 110
import rabbit4 from '../result_rabbit_img/rabbit4.png'; //큐피드 토끼 100
import rabbit5 from '../result_rabbit_img/rabbit5.png'; //마성의 매력 토끼 222
import rabbit6 from '../result_rabbit_img/rabbit6.png'; //두마리 토끼 다 잡은 토끼 202
import rabbit7 from '../result_rabbit_img/rabbit7.png'; //두마리 토끼 다 잡은 토끼 022
import rabbit8 from '../result_rabbit_img/rabbit8.png'; //두마리 토끼 다 잡은 토끼 220
import rabbit9 from '../result_rabbit_img/rabbit9.png'; //나혼산 토끼 000
import rabbit10 from '../result_rabbit_img/rabbit10.png'; //뭘해도 중간은 가는 토끼 111
import rabbit11 from '../result_rabbit_img/rabbit11.png'; //대장 토끼 121
import rabbit12 from '../result_rabbit_img/rabbit12.png'; //벼락성공 토끼 021
import rabbit13 from '../result_rabbit_img/rabbit13.png'; //복주머니 토끼 120
import rabbit14 from '../result_rabbit_img/rabbit14.png'; //돈많은 백수 토끼 011
import rabbit15 from '../result_rabbit_img/rabbit15.png'; //쇼미더머니 토끼 102
import rabbit16 from '../result_rabbit_img/rabbit16.png'; //재벌집 막내 토끼 112
import a1_1 from '../result_image/a1_1.png'; import a1_2 from '../result_image/a1_2.png';
import a2_1 from '../result_image/a2_1.png'; import a2_2 from '../result_image/a2_2.png';
import a3_1 from '../result_image/a3_1.png'; import a3_2 from '../result_image/a3_2.png';
import a4_1 from '../result_image/a4_1.png'; import a4_2 from '../result_image/a4_2.png';
import a5_1 from '../result_image/a5_1.png'; import a5_2 from '../result_image/a5_2.png';
import a6_1 from '../result_image/a6_1.png'; import a6_2 from '../result_image/a6_2.png';
import a7_1 from '../result_image/a7_1.png'; import a7_2 from '../result_image/a7_2.png';
import a8_1 from '../result_image/a8_1.png'; import a8_2 from '../result_image/a8_2.png';
import a9_1 from '../result_image/a9_1.png'; import a9_2 from '../result_image/a9_2.png';
import a10_1 from '../result_image/a10_1.png'; import a10_2 from '../result_image/a10_2.png';
import a11_1 from '../result_image/a11_1.png'; import a11_2 from '../result_image/a11_2.png';
import a12_1 from '../result_image/a12_1.png'; import a12_2 from '../result_image/a12_2.png';
import a13_1 from '../result_image/a13_1.png'; import a13_2 from '../result_image/a13_2.png';
import a14_1 from '../result_image/a14_1.png'; import a14_2 from '../result_image/a14_2.png';
import a15_1 from '../result_image/a15_1.png'; import a15_2 from '../result_image/a15_2.png';
import a16_1 from '../result_image/a16_1.png'; import a16_2 from '../result_image/a16_2.png';
import styled from 'styled-components';
import './resultText.css'

export const ResultData = [
  {
    id: 1,
    token: 'cf4175fd7b',
    name: '돌진 토끼',
    comment: "자신감 뿜뿜! 돌진하면 이뤄져요",
    best: '210',
    image: rabbit1,
    r_image: a1_1,
    r_image2 : a1_2,
    backgroundColor : '#EBEBFF',
    borderColor : 'black',
  },
  {
    id: 2,
    token: 'ad0bbf2599',
    name: '어쩌다 사랑 토끼',
    comment: "어쩌다보니 옆구리가 따뜻해",
    best: '200',
    image: rabbit2,
    r_image: a2_1,
    r_image2 : a2_2,
        backgroundColor : '#FFE7FF',
    borderColoer : 'black',
  },
  {
    id: 3,
    token: '40e482eaa0',
    name: '인기쟁이 토끼',
    comment : "올해의 당신은 인기쟁이!",
    best: '110',
    image: rabbit3,
    r_image: a3_1,
    r_image2 : a3_2,
        backgroundColor : '#E5FFE9',
    borderColor : 'black',
  },
  
  {
    id: 4,
    token: 'a42b7064df',
    name: '큐피드 토끼',
    comment : "큐피드의 화살을 맞게 될 당신!",
    best: '100',
    image: rabbit4,
    r_image: a4_1,
    r_image2 : a4_2,
        backgroundColor : '#FFE7E7',
    borderColor : 'black',
  },
  {
    id: 5,
    token: '64334b1b4a',
    name: '마성의 매력 토끼',
    comment : "토끼 복이 모두 굴러들어오는 한해!",
    best: '222',
    image: rabbit5,
    r_image: a5_1,
    r_image2 : a5_2,
        backgroundColor : '#E7EEFF',
    borderColor : 'black',
  },
  {
    id: 6,
    token: 'd2d63f789a',
    name: '두마리 토끼 다 잡은 토끼',
    comment : "두 마리 토끼를 잡은 당신은 욕심쟁이~",
    best: '202',
    image: rabbit6,
    r_image: a6_1,
    r_image2 : a6_2,    backgroundColor : '#D5DCEF',
    borderColor : 'black',
  },
  {
    id: 7,
    token: 'b3b1d185e4',
    name: '두마리 토끼 다 잡은 토끼',
    comment : "두 마리 토끼를 잡은 당신은 욕심쟁이~",
    best: '022',
    image: rabbit7,
    r_image: a7_1,
    r_image2 : a7_2,    backgroundColor : '#D5E7F3',
    borderColor : 'black',
  },
  {
    id: 8,
    token: '0838bab7a4',
    name: '두마리 토끼 다잡은 토끼',
    comment : "두 마리 토끼를 잡은 당신은 욕심쟁이~",
    best: '220',
    image: rabbit8,
    r_image: a8_1,
    r_image2 : a8_2,    backgroundColor : '#FAFFEF',
    borderColor : 'black',
  },
  {
    id: 9,
    token: 'ad998fcad0',
    name: '나혼산 토끼',
    comment:"올해는 잠시 쉬었다 가실게요~",
    best: '000',
    image: rabbit9,
    r_image: a9_1,
    r_image2 : a9_2,    backgroundColor : '#FDDFD7',
    borderColor : 'black',
  },
  {
    id: 10,
    token: '15789b70e2',
    name: '뭘해도 중간은 가는 토끼',
    comment: "가만히만 있어도 본전 가는 한해",
    best: '111',
    image: rabbit10,
    r_image: a10_1,
    r_image2 : a10_2,    backgroundColor : '#F0E9E0',
    borderColor : 'black',
  },
  {
    id: 11,
    token: 'bfaf148cd7',
    name: '대장 토끼',
    comment:"무엇이든 성공할 한 해!",
    best: '121',
    image: rabbit11,
    r_image: a11_1,
    r_image2 : a11_2,    backgroundColor : '#E5FBFF',
    borderColor : 'black',
  },
  {
    id: 12,
    token: '82791fa00c',
    name: '벼락성공 토끼',
    comment: "벼락처럼 눈부시게 성공할 한해!",
    best: '021',
    image: rabbit12,
    r_image: a12_1,
    r_image2 : a12_2,    backgroundColor : '#FFFFE7',
    borderColor : 'black',
  },
  {
    id: 13,
    token: '6ab5e4b717',
    name: '복주머니 토끼',
    comment :'복이 데굴데굴! 노력한자에게 선물이',
    best: '120',
    image: rabbit13,
    r_image: a13_1,
    r_image2 : a13_2,    backgroundColor : '#FFF1E1',
    borderColor : 'black',
  },
  {
    id: 14,
    token: '61f4343a13',
    name: '돈많은 백수 토끼',
    comment:'누구나 부러워할 성공과 재물이 한가득',
    best: '011',
    image: rabbit14,
    r_image: a14_1,
    r_image2 : a14_2,    backgroundColor : '#DDFFF2',
    borderColor : 'black',
  },
  {
    id: 15,
    token: 'bdd7320e1e',
    name: '쇼미더머니 토끼',
    comment:'남들이 부러워할 성공 SWAG',
    best: '102',
    image: rabbit15,
    r_image: a15_1,
    r_image2 : a15_2,    backgroundColor : '#FFF7DD',
    borderColor : 'black',
  },
  {
    id: 16,
    token: '0ac11dbcca',
    name: '재벌집 막내 토끼',
    comment:'뭐하나 빠짐없이 완벽한 한해',
    best: '112',
    image: rabbit16,
    r_image: a16_1,
    r_image2 : a16_2,    backgroundColor : '#F6E7FF',
    borderColor : 'black',
  },

]

const DescSub = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5px;
  black-space: pre-wrap;
  font-family: "CookieRun_Regular";

`