import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageImage from '../assets/main_image.PNG';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import axios from 'axios';
import Adfit from "./Adfit";
import { UrlAPI } from './parameter';
import KakaoShareBtn from '../component/KakaoShareBtn';
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
	FacebookShareButton,
	FacebookIcon,
	TwitterIcon,
	TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
let accUserNum = 0;
const Home = () => {
  const navigate = useNavigate();
  /* DB 데이터 추출 중지 | 23-01-04 | GB
  const [userNum, setUserNum] = useState(0);
  const getUserNum = async () => {
    try {
      axios
        .get(UrlAPI)
        .then(response => {
          console.log(`사용자 수: ${response.data}`);
          setUserNum(response.data);
        })
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  useEffect(() => {
    getUserNum();
  }, [])
  
  */
  const currentUrl = window.location.href;

  const handleClickButton = () => {
     navigate('/question');
  }

  const showUserNum = () => {
    return '5만 명+';
  };

  const resData = [
    {
      id: 1000,
      token: 'a42b7064df',
      name: '****',
      best: '200',
      desc: '****',
      image: PageImage,
      r_iamge: PageImage,
    }];

    return(
    <div style={{ }}>
    <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} />
    <Wrapper> 
      <Header>럭키 토끼 테스트</Header>
      <Contents>
      <Title>올해 나는 어떤 토끼?</Title>
        <br/>
        <LogoImage>
            <img alt='mainpage_img'
              src={PageImage} width={300} height={300}
              className="rounded-circle"
              style={{ borderRadius: '50%', border: '2px solid ' + 'white' }}
            />
          </LogoImage>
        {/* <LogoImage>
          <img alt='mainpage_img' src={PageImage} width={300} height={300} className="rounded-circle" />
        </LogoImage> */}
        <Desc>올해엔 로또를..?</Desc>
        <Button style={{marginRight:'0px'}} class="btn btn-primary btn-lg" onClick={handleClickButton}>START</Button>
        <FlexContainer>
          <Desc>공유하기</Desc>
          <GridContainer>
            <FacebookShareButton url={currentUrl}>
              <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={currentUrl}>
              <FacebookMessengerIcon size={48} round={true} borderRadius={24}></FacebookMessengerIcon>
            </FacebookMessengerShareButton>
            {/* <TwitterShareButton url={currentUrl}>
              <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
            </TwitterShareButton> */}
            <CopyToClipboard text={currentUrl}>
              <URLShareButton>URL</URLShareButton>
            </CopyToClipboard>
            <KakaoShareBtn data={{data: resData, pageID: 'startPage'}} />
          </GridContainer>
        </FlexContainer>

        </Contents>
        <Adfit height={100} width={320} unit={"kFdWVxcz4wh0SxJY"} scrFlag={true} position={"buttom"}></Adfit>
        <STitle>누적 테스트 수 : {showUserNum()}</STitle>
        <Footer> </Footer>
    </Wrapper>
    </div>
    );
}


export default Home;

const Wrapper = styled.div `
  //height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, #A661C2, #E8D9FF);
  text-align: center;
  white-space: pre-line;
`
const Header = styled.div `
  padding-top: 2rem;
  font-size: 27pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "CookieRun_Regular";
  font-weight: 500;
  color: white;
  text-align: center
`
const Contents = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.div `
  font-size: 25px;
  margin-top: 40px;
  color: white;
`

const STitle = styled.div `
  font-size: 17px;
  color: gray;
`

const LogoImage = styled.div `
  margin-top: 20px;
`
const Desc = styled.div `
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

// 제목과 버튼을 감싸는 컨테이너
const FlexContainer = styled.div`
  margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

// 버튼을 배치시키는 컨테이너
const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;