import noticeImg from '../notice.png';

export const QuestionData = [
  {
    id: 1,
    title: "사랑하는 사람의 생일이에요.\n어떻게 선물을 고를까요?",
    answerA: "내가 주고 싶은 물건",
    answerB: "예산에 맞춰 고르라 한다",
    type: "LOVE",
  },
  {
    id: 2,
    title: "미래에 살고 싶은 집은?",
    answerA:
      "2층인 서양식 집",
    answerB:
      "도심의 고층 아파트",
    type: "MONEY",
  },
  {
    id: 3,
    title: "친구와 함께 자취하는데\n설거지가 밀려있다",
    answerA: "네가 설거지 한다고? 최고야~!",
    answerB: "그동안 나만 설거지했으니 이제 네 차례야",
    type: "SUCCESS",
  },
  {
    id: 4,
    title: "해외여행 중 길을 잃어버렸다.\n그 순간 저 멀리 큰 집이 있다",
    answerA: "울타리는 없다",
    answerB: "울타리가 보인다",
    type: "LOVE",
  },
  {
    id: 5,
    title: "옆으로는 숲이 펼쳐져 있습니다.\n그 숲은 ?",
    answerA: "열매가 실한 과일 나무들로 이루어진 숲이다",
    answerB: "곧은 대나무들이 쭉쭉 뻗어 있는 숲이다 ",
    type: "SUCCESS",
  },
  {
    id: 6,
    title: "집에 조금 더 가까이 다가가니 대문이?",
    answerA: "굳게 닫혀있다",
    answerB: "살짝 열려있다",
    type: "MONEY",
  },
  {
    id: 7,
    title: "안정적인 서버 운영비 조달을 위해\n쿠팡 광고를 삽입했어요😅\n\n쿠팡 광고 뜨면 놀라지 마시고\n 뒤로가기 누르시면 결과 확인하실 수 있어요🙏",
    answerA: "쿠팡 광고보고 결과 확인하기!",
    answerB: "X",
    type: "",
    img: noticeImg,
  }
];